import React from "react"

const Footer = () => (

    <div>
        <footer className="footer">
            <p className="footer__copy">Copyright &#169; {new Date().getFullYear()}
            {` `}
            <a href="https://www.musixmize.com" className="footer__copy"><strong className="footer__link">MusixMize</strong></a>. All right reserved</p>
        </footer>
    </div>
)

export default Footer
import React, { Component } from "react"
import { Link } from "gatsby"
import { FaBars } from 'react-icons/fa'
import Logo from "../images/svg/logo.inline.svg"

class Navbar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            active: false
        };
    }

    handleClick = () => {
        this.setState({ active: !this.state.active });
        const nav = document.getElementById('nav-menu')
        if(nav) {
            nav.classList.toggle('show-menu')
        }
    }

    closeMenuClick = () => {
        this.setState({ active: !this.state.active});
        const nav = document.getElementById('nav-menu')
        if(nav) {
            nav.classList.remove('show-menu')
        }

    }

    render() {
        console.log("active : " + JSON.stringify(this.props));
        return (
            
            <header className="l-header" id="header">
                <nav className="nav bd-container">
                    <div>
                        <Link to="/">
                            <Logo className="nav__logo" />
                        </Link>
                    </div>
                    <div className="nav__menu" id="nav-menu">
                        <ul className="nav__list">
                            <li className="nav__item"><a href="/#home" className="nav__link" onClick={() => this.closeMenuClick()}>Home</a></li>
                            <li className="nav__item"><a href="/#features" className="nav__link" onClick={() => this.closeMenuClick()}>Features</a></li>
                            <li className="nav__item"><a href="/#download" className="nav__link" onClick={() => this.closeMenuClick()}>Download</a></li>
                            <li className="nav__item"><a href="/#contact" className="nav__link" onClick={() => this.closeMenuClick()}>Contacts</a></li>
                        </ul>
                    </div>

                    <div className={
                        "nav__toggle " + (this.state.active ? "is-active" : "")
                    }
                        id="nav-toggle"
                        onClick={this.handleClick}>
                        <i className="bx"><FaBars /></i>
                    </div>
                </nav>
            </header>
        );
    }
}

export default Navbar